import { Component } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  alphabet = ['b', 'q', 'p', 'd'];
  game = [];
  previousGame = [];
  gameStarted: boolean;
  solution: number;
  targetLetter: string;
  userAnswer: number;
  gameInterval: any;
  initialTime = 20;
  timeLeft: number = this.initialTime;
  spinnerValue = 100;

  newGame() {
    this.gameStarted = true;
    this.selectTargetLetter();
    this.timeLeft = this.initialTime;
    this.userAnswer = undefined;
    this.fillGame();
    this.previousGame = cloneDeep(this.game);
    this.solution = this.game.filter(letter => letter === this.targetLetter).length;
    this.gameInterval = setInterval(() => {
      this.timeLeft --;
      this.spinnerValue = Math.round((this.timeLeft / this.initialTime) * 100);
      if (this.timeLeft === 0) {
        this.obfuscateLetters();
        clearInterval(this.gameInterval);
      }
    }, 1000);
  }

  obfuscateLetters() {
    this.game.forEach((value, index) => {
      this.game[index] = '#';
    });
  }

  selectTargetLetter() {
    const index = Math.floor(Math.random() * this.alphabet.length);
    this.targetLetter = this.alphabet[index];
  }

  fillGame() {
    this.game = [];
    for (let i = 0; i < 60; i++) {
      const randomIndex = Math.floor(Math.random() * this.alphabet.length);
      this.game.push(this.alphabet[randomIndex]);
    }
  }

  endGame() {
    this.gameStarted = false;
    this.game = this.previousGame;
    clearInterval(this.gameInterval);
  }
}
